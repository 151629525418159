import React from 'react'
import { graphql } from 'gatsby'
import { get, capitalize} from 'lodash'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Accordion from '../components/accordion'
import './placements.css'

class Artists extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    
    const all_placements = get(this, 'props.data.all_placements.edges')
    let years_array = [];
    all_placements.map((placement) => {
      if(typeof(years_array[Number(placement.node.year)]) === 'undefined') {
          // does not exist
          years_array[Number(placement.node.year)] = [{
            year: placement.node.year,
            title: placement.node.title,
            tag: placement.node.tag,
            link: placement.node.link
          }];
      }
      else {
          // does exist
          years_array[Number(placement.node.year)].push({
            year: placement.node.year,
            title: placement.node.title,
            tag: placement.node.tag,
            link: placement.node.link
          })
      }
    });
    years_array.reverse();  
    
    return (
      <Layout location={this.props.location}>
        <div className="page placements-page page-wrapper">
          <Helmet title={siteTitle} />
          {
            years_array.map(
              (year, i) => <Accordion
                title={
                  <div className={"section-title"}>
                    {year[0].year}    
                    <span className="accordion__icon">⇩</span>
                  </div>              
                }
                content={              
                  <ul>                
                    {year.map((node) => {
                      return (
                        <li key={node.title}>
                          <span className="type" data-color={node.tag}>{node.tag}</span>
                          <a href={node.link} target="_blank" rel="noreferrer">{capitalize(node.title)}</a>
                        </li>
                      )
                    })}              
                  </ul>
                }      
              />
            )
          }                                                         
        </div>
      </Layout>
    )
  }
}

export default Artists

export const pageQuery = graphql`
  query Placements {        
    all_placements: allContentfulPlacement {
      edges {
        node {
          year
          title
          link
          tag        
        }
      }      
    }
  }
`
